body {
  padding-bottom: 100px;
}

.App {
  padding: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.form-help-txt,
.form-err-txt {
  font-size: 12px;
  color: #999;
}

.form-err-txt {
  color: tomato;
}

.gua {
  display: grid;
  row-gap: 15px;
}

.yao {
  height: 25px;
  position: relative;
  display: grid;
}
.yao.yin {
  column-gap: 15px;
  grid-template-columns: 1fr 1fr;
}

.yao-item {
  background-color: #000;
}

.meihua h3 {
  text-align: center;
  margin-bottom: 0px;
}

.meihua-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.gua-name {
  text-align: center;
  color: #666;
  display: block;
}

.meihua-table {
  width: 100%;
}

.meihua-table,
.meihua-table tr,
.meihua-table td {
  border: 1px solid rgb(160, 160, 160);
  border-collapse: collapse;
  padding: 5px 15px;
}

.meihua-table td:first-child:not(:only-child, .nostyle) {
  text-align: center;
  background-color: #f3ecfc;
  font-weight: bold;
}

.tiyong {
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
}

.gray-text {
  color: #666;
}

.shengke-icon {
  text-align: center;
}

.double-arrow {
  transform: rotate(90deg);
}

.shengke {
  margin: 0;
}

.shengke-name {
  color: #cc0000;
  font-weight: normal;
}

.gua-container {
  position: relative;
}

.cool,
.shensha {
  color: #000;
  text-shadow: 1px 1px 2px #fff;
  position: absolute;
  bottom: 0;
  left: 5px;
  font-weight: bold;
  line-height: 24px;
  font-size: 13px;
}

.shensha {
  top: 0;
  right: 5px;
  bottom: auto;
  display: flex;
  justify-content: space-between;
}

.footer {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
}

.guaxiang .ant-space {
  gap: 50px;
}

.variable {
  position: absolute;
  left: -15px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

td {
  cursor: default;
}

td.active {
  background-color: rgba(2, 2, 2, 0.1);
}

.donate {
  position: fixed;
  inset: 0px;
  z-index: 99;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .guaxiang .ant-space {
    gap: 10px;
  }
  .gua {
    gap: 10px;
  }
  .yao {
    width: 100px;
  }
  .yao.yin {
    gap: 5px;
  }
  .tiyong {
    font-size: 25px;
  }
  .variable {
    left: -10px;
  }
  .meihua-table,
  .meihua-table tr,
  .meihua-table td {
    font-size: 12px;
  }
  .shengke {
    font-size: 12px;
  }
  .meihua h3 {
    margin-top: 5px;
  }
}
